import { AbstractLocaleMessageObject } from "@/i18n/AbstractLocaleMessageObject"

export class EventListJa extends AbstractLocaleMessageObject {
  readonly idTypeItemShopEventId: string = "加盟店管理ID"

  readonly idTypeItemEventId: string = "イベントID"

  readonly quickSearchValue: string = "簡易検索値"

  readonly searchShopEventIdPlaceholder: string = "最大100文字。複数入力はスペースで区切る。"

  readonly searchEventIdPlaceholder: string = "最大47文字。複数入力はスペースで区切る。"

  readonly searchableWithEnter: string = "Enterで検索可能。"

  readonly detailSearchButton: string = "検索条件▼"

  readonly detailSearchAreaTitle: string = "詳細検索"

  readonly detailSearchEventAreaTitle: string = "イベント情報"

  readonly ruleCode: string = "発動ルールコード"

  readonly ruleCodePlaceholder: string = "最大100文字。複数入力はスペースで区切る。"

  readonly tenantName: string = "店子"

  readonly tenantNamePlaceholder: string = "最大200文字。複数入力はスペースで区切る。"

  readonly ruleDescription: string = "ルール説明"

  readonly ruleDescriptionPlaceholder: string = "最大1024文字。複数入力はスペースで区切る。"

  readonly amount: string = "決済金額"

  readonly amountMinimum: string = "決済金額"

  readonly amountMinimumPlaceholder: string = "半角数字。最大9文字。"

  readonly amountRange: string = "～"

  readonly amountMax: string = "決済金額"

  readonly amountMaxPlaceholder: string = "半角数字。最大9文字。"

  readonly settleStatus: string = "決済ステータス"

  readonly method: string = "決済方法"

  readonly issuer: string = "カード発行元"

  readonly createdAt: string = "自動審査日時"

  readonly createdAtFromDate: string = "日付"

  readonly createdAtFromHour: string = "時"

  readonly createdAtFromMinute: string = "分"

  readonly datetimeRange: string = "～"

  readonly createdAtToDate: string = "日付"

  readonly createdAtToHour: string = "時"

  readonly createdAtToMinute: string = "分"

  readonly aaresult: string = "自動審査結果"

  readonly maresult: string = "目視審査結果"

  readonly deadline: string = "目視審査締切日時"

  readonly deadlineFromDate: string = "日付"

  readonly deadlineFromHour: string = "時"

  readonly deadlineFromMinute: string = "分"

  readonly deadlineToDate: string = "日付"

  readonly deadlineToHour: string = "時"

  readonly deadlineToMinute: string = "分"

  readonly authoriModel: string = "審査モデル"

  readonly authStatus: string = "審査ステータス"

  readonly assignee: string = "審査担当者"

  readonly reason: string = "目視審査理由"

  readonly settleCategory: string = "ネガティブ決済カテゴリ"

  readonly otherCategory: string = "ネガティブその他カテゴリ"

  readonly label: string = "ラベル"

  readonly detailSearchItemAreaTitle: string = "商品情報"

  readonly shopItemId: string = "加盟店商品ID"

  readonly shopItemIdPlaceholder: string = "最大512文字。複数入力はスペースで区切る。"

  readonly itemName: string = "商品名"

  readonly itemNamePlaceholder: string = "最大500文字。複数入力はスペースで区切る。"

  readonly price: string = "単価"

  readonly pricePlaceholder: string = "半角数字。最大9文字。"

  readonly quantity: string = "数量"

  readonly quantityPlaceholder: string = "半角数字。最大9文字。"

  readonly category: string = "商品カテゴリ"

  readonly categoryPlaceholder: string = "最大20文字。複数入力はスペースで区切る。"

  readonly stock: string = "在庫有無"

  readonly detailSearchCustomerAreaTitle: string = "個人情報"

  readonly customerTypeItemPurchaser: string = "購入者情報で検索"

  readonly customerTypeItemShipping: string = "配送先情報で検索"

  readonly customerTypeItemBoth: string = "購入者・配送先両方で検索"

  readonly shopMemberId: string = "会員ID"

  readonly shopMemberIdPlaceholder: string = "最大100文字。複数入力はスペースで区切る。"

  readonly lastName: string = "苗字"

  readonly lastNamePlaceholder: string = "最大150文字（ハッシュ値）"

  readonly firstName: string = "名前"

  readonly firstNamePlaceholder: string = "最大150文字（ハッシュ値）"

  readonly sex: string = "性別"

  readonly birthdate: string = "生年月日"

  readonly company: string = "会社名"

  readonly companyPlaceholder: string = "最大100文字。"

  readonly depart: string = "部署名"

  readonly departPlaceholder: string = "最大100文字。"

  readonly existingCustomerFlg: string = "顧客分類"

  readonly zipcode: string = "郵便番号"

  readonly zipcodePlaceholder: string = "最大100文字。"

  readonly fullAddress: string = "住所"

  readonly fullAddressPlaceholder: string = "最大200文字。"

  readonly tel: string = "電話番号"

  readonly telPlaceholder: string = "最大20文字。"

  readonly deliveryCompany: string = "配送業者"

  readonly deliveryCompanyPlaceholder: string = "最大100文字。複数入力はスペースで区切る。"

  readonly emailAccount: string = "メールアカウント"

  readonly emailAccountPlaceholder: string = "最大64文字（ハッシュ値）"

  readonly emailDomain: string = "メールドメイン"

  readonly emailDomainPlaceholder: string = "最大253文字。"

  readonly detailSearchDeviceAreaTitle: string = "デバイス情報"

  readonly ipaddress: string = "IPアドレス"

  readonly ipaddressPlaceholder: string = "最大50文字。複数入力はスペースで区切る。"

  readonly cookie: string = "Cookie"

  readonly cookiePlaceholder: string = "最大500文字。複数入力はスペースで区切る。"

  readonly primaryDid: string = "デバイスID"

  readonly primaryDidPlaceholder: string = "最大38文字。複数入力はスペースで区切る。"

  readonly userDeviceId: string = "端末ID"

  readonly userDeviceIdPlaceholder: string = "最大64文字。複数入力はスペースで区切る。"

  readonly deviceType: string = "デバイス種別"

  readonly didShort: string = "DID short"

  readonly didShortPlaceholder: string = "最大128文字。"

  readonly didMiddle: string = "DID middle"

  readonly didMiddlePlaceholder: string = "最大128文字。"

  readonly authoriId: string = "O-MOTION審査ID"

  readonly authoriIdPlaceholder: string = "最大64文字。複数入力はスペースで区切る。"

  readonly result: string = "O-MOTION審査結果"

  readonly closeButton: string = "閉じる"

  readonly includeDeleted: string = "削除済みを含める"

  readonly negativeOnly: string = "ネガティブのみ"

  readonly searchButton: string = "検索"

  readonly conditionClearButton: string = "条件クリア"

  readonly tableHeaderShopEventId: string = "加盟店管理ID"

  readonly tableHeaderRelatedCount: string = "関連件数"

  readonly tableHeaderAuthoriModelId: string = "審査モデル"

  readonly tableHeaderAaresult: string = "自動審査結果"

  readonly tableHeaderMaresult: string = "目視審査結果"

  readonly tableHeaderSettleStatus: string = "決済ステータス"

  readonly tableHeaderCreatedAt: string = "自動審査日時"

  readonly tableHeaderAmount: string = "決済金額"

  readonly tableHeaderTenantName: string = "店子"

  readonly tableHeaderShopMemberId: string = "購入者会員ID"

  readonly tableHeaderLastName: string = "苗字"

  readonly tableHeaderFirstName: string = "名前"

  readonly tableHeaderZipcode: string = "郵便番号"

  readonly tableHeaderFullAddress: string = "住所"

  readonly tableHeaderTel: string = "固定電話番号"

  readonly tableHeaderMobileTel: string = "携帯電話番号"

  readonly tableHeaderMailAccount: string = "PCメールアカウント"

  readonly tableHeaderMailDomain: string = "PCメールドメイン"

  readonly tableHeaderMobileMailAccount: string = "携帯メールアカウント"

  readonly tableHeaderMobileMailDomain: string = "携帯メールドメイン"

  readonly empty: string = "－"

  readonly quickSearchButton: string = "簡易検索"

  readonly detailSearchCloseButton: string = "閉じる▲"
}
